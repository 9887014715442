
<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Brokers</h1>
    </div>
    <br />
    <DataGridV3 :configuracion="dataGridConfig" :data="dataSourceGrid" :seleccionar="seleccionar" ref="gridBrokers"  @ready="cargarAcciones">
      <div class="row">
        <div class="col-sm-auto">
          <button class="btn btn-info" @click="cargar()"> <i class='fa fa-refresh'></i> Recargar</button>
        </div>
        <div class="col-sm-auto" v-if="$auth.can('garantias_app','brokers_crear')">
          <button  class="btn btn-primary" @click="crear"><i class='fa fa-plus-circle'></i> Nuevo</button>
        </div>
      </div>

    </DataGridV3>

    <formulario-broker v-if="openModalForm" :prop_broker="itemSeleccionado.id" @cerrar="cancelar_seleccion" @recargar="cargar"></formulario-broker>


  </div>
</template>

<script>

import Modal from "@/components/Modal.vue";
import DataGridV3 from "@/components/DataGridV3.vue";
import BrokersApi from "@/apps/garantias_app/api/brokers/brokers.api";
import FormularioBroker from "@/apps/garantias_app/pages/brokers/FormularioBroker.vue";

export default {
  name: "Brokers",
  data(){
    return {
      openModalForm: false,
      openModalEliminar: false,
      dataGridConfig: {
        name: 'garantias_brokers',
        cols: {
          acciones: '',
          external_id: 'ID externo',
          nombre: 'Nombre',
          estatus: 'Estatus',
        }
        ,filters: {
          cols: {
            external_id: 'ID externo',
            nombre: 'Nombre',
            estatus: 'Estatus',
          }
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          acciones: function(val,row,vue) {
            let acciones = "<div class='text-center'>";
            if (vue.$auth.can('garantias_app','brokers_editar'))
              acciones += "<i data-action='editar' data-opcion='"+vue.$helper.toJson(row)+"' class='btn-acciones btn-sm btn-primary fa fa-edit mr-sm-2'></i>";

            return acciones+"</div>";
          }
          ,estatus:function (val){
            return val.toUpperCase()
          }
        },
        show_search: true,
        search: ['nombre'],
        selector: false,
      },
      seleccionar: false,
      dataSourceGrid: [],
      accionesCargadas: false,
      itemSeleccionado: {
        id: null,
        identificador_externo: null,
        nombre: null,
        estatus: null
      }
    }

  },
  components: {
    FormularioBroker,
    DataGridV3,
    Modal
  },
  computed: {

  },
  mounted() {
    this.cargar()
  },
  methods: {
    cargarAcciones() {
      let btns = document.querySelectorAll('.btn-acciones');
      btns.forEach(btn => {
        btn.removeEventListener('click', this.ejecutarAccionBtn )
        btn.addEventListener('click', this.ejecutarAccionBtn)
      })
    },
    ejecutarAccionBtn(e){
      let seleccion  = this.$helper.fromJson(e.target.dataset.opcion);
      if (seleccion.id !== this.itemSeleccionado.id) {
        let accion = e.target.dataset.action;
        this.itemSeleccionado = {...seleccion}
        this.itemSeleccionado.identificador_externo = this.itemSeleccionado.external_id
        this[accion](seleccion);
      }
    }
    ,cancelar_seleccion(){
      this.openModalForm = false;
      this.openModalEliminar = false;

      this.itemSeleccionado = {
        id: null,
        identificador_externo: null,
        nombre: null
      };
    },
    crear(){
      this.cancelar_seleccion()
      this.openModalForm = true;
    },
    async editar(){
      this.openModalForm = true;
    },

    async guardar(){
      let isValid = await this.$validator({
        identificador_externo: !!this.itemSeleccionado.identificador_externo ? 'max:255' : '',
        nombre: !this.itemSeleccionado.nombre ? 'required' : 'max:150',
      })

      if (isValid) {
        try {

          if (this.itemSeleccionado.id!== null) {
            await BrokersApi.editar(this.itemSeleccionado.id,  payload)
            this.$helper.showMessage('Editar broker', 'Información guardada exitosamente.', 'success', 'alert')

          }else{
            await BrokersApi.crear(payload)
            this.$helper.showMessage('Crear broker', 'Información guardada exitosamente.', 'success', 'alert')
          }
          this.cancelar_seleccion()
          await  this.cargar()

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage( (!this.cliente_id ? 'Guardar' : 'Editar')+ ' broker', 'Ocurrio un error al guardar datos del broker, favor de reportarlo al administador.', 'error', 'alert')
        }
      }
    },

    async cargar() {
      this.cancelar_seleccion()
      let dataSource = await BrokersApi.index(false)
      this.dataSourceGrid = dataSource.data
    }
  }

}
</script>


<style scoped lang="scss">
.btn-acciones:hover{
  cursor: pointer;
  filter: brightness(80%);
}
</style>