
<template>

  <Modal :options="{width: !editar ? '45vw' : '65vw',type:'info'}" @close="cerrar_formulario">
    <div class="title">{{ !editar ? 'Agregar Broker' : 'Editar Broker'}}</div>
    <div class="body">

      <div class="alert alert-primary mb-5" role="alert">
        <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan el icono <i class="fa fa-check-circle mx-sm-1"></i> indican que el campo es obligatorio.
      </div>

      <div class="row justify-content-center mt-4">
        <div class="form-group" :class="!editar ? 'col-12' : 'col-4'">
          <label for="indentificador" class="form-label text-truncate d-block" title="Identificador externo">
            Identificador externo
          </label>
          <div class="text-bold" v-if="broker_inactivo">
            <i v-if="!itemSeleccionado.identificador_externo" class="text-danger">Valor no ingresado</i>
            <b v-else>{{itemSeleccionado.identificador_externo}}</b>
          </div>
          <input v-else v-model="itemSeleccionado.identificador_externo" type="text" name="identificador_externo" id="identificador_externo" class="form-control">
        </div>

        <div class="form-group" :class="!editar ? 'col-12' : 'col-4'">
          <label for="nombre" class="form-label text-truncate d-block" title="Nombre">
            <i class="fa fa-check-circle text-primary"></i>
            Nombre
          </label>
          <div v-if="broker_inactivo" class="text-bold"><b>{{itemSeleccionado.nombre}}</b></div>
          <input v-else v-model="itemSeleccionado.nombre" type="text" name="nombre" id="nombre" class="form-control">
        </div>

        <div class="form-group" :class="!editar ? 'col-12' : 'col-4'">
          <label for="estatus" class="form-label text-truncate d-block" title="Estatus">
            <i class="fa fa-check-circle text-primary"></i>
            Estatus
          </label>
          <select class="form-control" name="estatus" id="estatus"  v-model="itemSeleccionado.estatus" >
            <option value="activo">Activo</option>
            <option value="inactivo">Inactivo</option>
          </select>
        </div>
      </div>

      <div class="card mt-5 border-0" v-if="editar">
        <div class="card-body">
          <ul class="nav nav-pills nav-fill text-center mb-1" style="border-bottom: solid 1px #e0e0e0">
            <li class="nav-item">
              <a class="nav-link rounded-0" :class="(tab_activa === 'tab-garantias') && 'active'" aria-current="page" href="#" @click="change_tab('tab-garantias')"><i class="fa fa-home mr-sm-2"></i> Garantías </a>
            </li>
          </ul>

          <div class="tab-content" id="nav-tabContent">
            <div class="container tab-pane fade mt-4 pt-4" :class="(tab_activa === 'tab-garantias') && 'show active'" v-if="tab_activa === 'tab-garantias'">
              <brokers-garantias :broker_id="itemSeleccionado.id" :prop_solo_lectura="broker_inactivo"></brokers-garantias>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="row justify-content-end">
        <div class="col-auto"><button class="btn btn-primary" @click="guardar"><i class="fa fa-check-square mr-sm-1"></i>{{ !itemSeleccionado.id ? 'Guardar' : 'Guardar Cambios'}}</button></div>
        <div class="col-auto"><button class="btn btn-outline-secondary" @click="cerrar_formulario"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
      </div>
    </div>
  </Modal>
    

</template>

<script>

import Modal from "@/components/Modal.vue";
import DataGridV3 from "@/components/DataGridV3.vue";
import FormularioCliente from "@/apps/garantias_app/pages/clientes/FormularioCliente.vue";
import Cliente from "@/apps/garantias_app/pages/clientes/Cliente.vue";
import brokersApi from "@/apps/garantias_app/api/brokers/brokers.api";
import FideicomisoCesionarios from "@/apps/garantias_app/pages/fideicomisos/FideicomisoCesionarios.vue";
import HonorariosFiduciarios from "@/apps/garantias_app/pages/fideicomisos/HonorariosFiduciarios.vue";
import FideicomisoBeneficiarios from "@/apps/garantias_app/pages/fideicomisos/FideicomisoBeneficiarios.vue";
import FideicomisoGarantias from "@/apps/garantias_app/pages/fideicomisos/FideicomisoGarantias.vue";
import BrokersGarantias from "@/apps/garantias_app/pages/brokers/BrokersGarantias.vue";

export default {
  name: "FormularioBroker",
  components: {
    BrokersGarantias,
    FideicomisoGarantias, FideicomisoBeneficiarios, HonorariosFiduciarios, FideicomisoCesionarios,
    FormularioCliente,
    Cliente,
    DataGridV3,
    Modal
  },
  props: {
    prop_broker: {
      type: null | String
      , required: false
      , default() {
        return null
      }
    }
  },
  data(){
    return {
      seleccionar: false,
      itemSeleccionado: {
        id: null,
        identificador_externo: null,
        nombre: null,
        estatus: null
      }
      ,tab_activa:'tab-garantias'
    }

  },
  computed: {
    editar(){
      return typeof this.itemSeleccionado .id == 'string';
    }
    ,broker_inactivo(){
      return !!this.itemSeleccionado.estatus && this.itemSeleccionado.estatus.toLowerCase() === 'inactivo'
    }
    ,estatus(){
    return this.itemSeleccionado.estatus ? this.itemSeleccionado.estatus.toLowerCase() : ''
  }
  },
  mounted() {

    if (!!this.prop_broker)
      this.cargar()

  },
  methods: {

    cerrar_formulario(){
      this.$emit('cerrar')
    },

    async cargar(){

      const dataSource = await brokersApi.obtener(this.prop_broker)
      this.itemSeleccionado = dataSource.data

    },
    async guardar(){
      let isValid = await this.$validator({
        identificador_externo: !!this.itemSeleccionado.identificador_externo ? 'max:255' : '',
        nombre: !this.itemSeleccionado.nombre ? 'required' : 'max:150',
      })

      if (isValid) {
        try {

          let payload = {...this.itemSeleccionado}


          if (!this.itemSeleccionado.identificador_externo){
            const {identificador_externo,...dataItem} = payload
            payload = {...dataItem}
          }


          if (this.itemSeleccionado.id!== null) {
            await brokersApi.editar(this.itemSeleccionado.id,  payload)
            this.$helper.showMessage('Editar broker', 'Información guardada exitosamente.', 'success', 'alert')

          }else{
            const response = await brokersApi.crear(payload)
            this.$helper.showMessage('Crear broker', 'Información guardada exitosamente.', 'success', 'alert')
            this.prop_broker = response.data.id
          }
          this.$emit('recargar')
          await  this.cargar()

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage( (!this.cliente_id ? 'Guardar' : 'Editar')+ ' Broker', 'Ocurrio un error al guardar datos del broker, favor de reportarlo al administador.', 'error', 'alert')
        }
      }
    },

  }

}
</script>


<style scoped lang="scss">
.btn-acciones:hover{
  cursor: pointer;
  filter: brightness(80%);
}
</style>